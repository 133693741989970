import * as React from 'react'
import loadable from '@loadable/component'

const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const SelectTitle = loadable(() =>
    import('/src/components/Form/Specialised/SelectTitle')
)
const TextField = loadable(() => import('/src/components/Form/TextField'))

const StepUserDetails = ({ isSubmitting, isSubmitted }) => {
    return (
        <FieldSet>
            <FieldSpacer grow={'stretch'}>
                <SelectTitle
                    label={'Title'}
                    id={'Title'}
                    autocomplete={'honorific-prefix'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                />
            </FieldSpacer>

            <FieldSpacer grow={'stretch'}>
                <TextField
                    label={'First Name'}
                    name={'firstname'}
                    id={'firstname'}
                    autocomplete={'given-name'}
                    placeholder={'e.g. Katie'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                    required
                />
            </FieldSpacer>

            <FieldSpacer grow={'stretch'}>
                <TextField
                    label={'Last Name'}
                    name={'lastname'}
                    id={'lastname'}
                    autocomplete={'family-name'}
                    placeholder={'e.g. Jones'}
                    isSubmitting={isSubmitting}
                    isSubmitted={isSubmitted}
                    required
                />
            </FieldSpacer>
        </FieldSet>
    )
}

export default StepUserDetails
